.App {
  text-align: center;
  background-color: rgb(6,12,233);
  min-height: 100vh;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-flex-v {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-flex-v .App-flex-v{
  min-height: auto;
}
.App-flex-h {
  display: flex;
  min-width: 100vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.App-flex-v-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-header{
  font-size: calc(10px + 2vmin);
}
.App-flex-grow{
  flex-grow: 1;
}
.App-flex-grow-p8{
  flex-grow: 0.8;
}

.App a {
  color: #61dafb;
}
.App a.disabled {
  color: grey;
  cursor: default;
}
.App button {
  font-size: 2em;
  background: rgb(31, 99, 0);
  color: white;
  border-radius: .2em;
  border-color: white;
  padding: 4px;
  margin: 4px;
}
.App button:disabled{
  filter: grayscale();
}
.logo-tiny{
  height: 14vh;
}
table.jeopardy{
  width: 100vw;
  border-collapse: collapse;
}
table.jeopardy th, td{
  border: 2px solid black;
  height: 13vh;
  font-size: 6vh;
}
table.jeopardy th{
  text-transform: capitalize;
  font-weight: bold;
  color: white;
}
table.jeopardy td{
  cursor: pointer;
  color: gold;
  font-weight: bolder;
}
.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(6,12,233);
  display: flex;
  flex-direction: column;
}
.modal .question{
  font-size: 25vh;
  white-space: pre-wrap;
  flex-grow: 1;
}
.modal .question.is-image{
  background-repeat: no-repeat;
  background-position-x: center;
  text-shadow: 1px 0px 6px black;
  background-position-y: center;
  background-size: contain;
}
.modal .question .question-image{
  max-width: 100%;
}
.modal button.blue{
  background-color: gold;
  color: black;
  font-weight: bold;
  width: 44px;
}
.team-display .team-display-name{
  margin: auto;
  background: rgb(31, 99, 0);
  border: 2px solid white;
  border-radius: 2vh;
  padding-left: 1vw;  
  padding-right: 1vw;  
  font-size: 5vh;
  margin-top: 1vh;
}
.team-display .team-display-points{
  margin: auto;
  font-size: 5vh;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button.team-name-small{
  font-size: 1.5em;
}
.h-14vh{
  height: 14vh;
}